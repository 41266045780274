@import "nlib/config";

.transactionsIntroModal {
  .title {
    color: $uiNeutralColor;
    font-size: 0.875rem;
    font-weight: normal;
  }
  .avatar {
    display: inline-flex;
    margin-right: $contentDefaultIndent;
    background-color: #eee !important;
    img {
      height: 100% !important;
    }
  }
  .modalContent {
    font-size: 0.875rem;
    text-align: center;
    h4 {
      margin-top: 0;
      color: $uiPrimaryColor;
      img {
        width: 1em;
        margin-left: 0.3em;
        vertical-align: top;
      }
    }
    p {
      margin-bottom: 0.75em;
    }
  }
  .video {
    position: relative;
    margin: $contentDefaultIndent * 2 $contentDefaultIndent * -3 $contentDefaultIndent * -3;
    &::after {
      content: "";
      display: block;
      padding-top: 56.25%;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}
[data-mobile] {
  .modalComponent {
    .modalContent {
      h4 {
        margin: 0;
        font-size: 1.25rem;
      }
    }
  }
}
@media (max-width: 500px) {
  .modalComponent {
    .modalContent {
      h4 {
        white-space: pre;
      }
    }
  }
}
